
@mixin buttonStyling( $textColor, $bgColor, $bgColorHover ) {

  color: $color-white;
  background-color: $color-rocket-red;
  border-width: $border-width-button;
  border-color: $bgColor;
  border-style: solid;
  border-radius: $border-radius-button;
  transition: background-color $transition-duration, border-color $transition-duration;
  padding: $primary-button-padding;
  font-family: $font-body;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  margin-right: 0.5em;

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.hover {
    color: $color-white;
    background-color: $color-rocket-red-mid;
    border-color: $bgColorHover;
    cursor: pointer;
  }

  &[disabled],
  &.disabled {
    background-color: $color-black-tint-20 !important;
    border-color: $color-black-tint-20 !important;
  }

  &.mat-button {
    padding: 0.35em 2em;
    border-radius: $border-radius-button;
    text-shadow: none;
    &[disabled],
    &.disabled {
      border-color: $color-black-tint-20 !important;
      background-color: $color-black-tint-20 !important;
      color: $color-white !important;
    }
  }

  &:focus {
    outline: none;
  }

  &.icon-button {
    padding: 11px 16px;
  }

}

button,
.button,
[mat-button] {

  @include buttonStyling( $color-white, $color-rocket-red, $color-rocket-red-mid );

  &.primary {
    @include buttonStyling( $color-white, $color-rocket-red, $color-rocket-red-mid );
  }

  &.secondary {
    background-color: $color-white;
    color: $color-orbit-purple;
    border: 2px solid $color-orbit-purple;
    border-radius: 3px;

    &.has-icon {
      i {
        font-size: 20px;
        padding-right: .5em;
      }
    }

    &.hover,
    &:hover {
      background-color: $color-orbit-purple-lightest;
      border: 2px solid $color-orbit-purple;
      border-radius: 3px;
    }

    &[disabled],
    &.disabled {
      color: $color-black-tint-20 !important;
      background-color: transparent !important;
      border: 2px solid $color-black-tint-20 !important;
      border-radius: 3px;
      pointer-events: none;
      opacity: 1 !important;
    }
  }

  &.tertiary {
    background-color: $color-transparent;
    color: $color-orbit-purple;
    border: 2px solid transparent;
    border-radius: 3px;
    &.has-icon {
      i {
        font-size: 20px;
        padding-right: .5em;
      }
    }
    &.hover, &:hover {
      border: 2px solid $color-orbit-purple;
      background-color: $color-orbit-purple-lightest;
      border-radius: 3px;
    }
    &[disabled],
    &.disabled {
      color: $color-black-tint-20 !important;
      background-color: transparent !important;
      border: 2px solid transparent !important;
      border-radius: 3px;
      pointer-events: none;
      opacity: 1 !important;
    }
  }

  .icon {
    i {
      font-size: 16px;
      padding-right: 0.5em;
    }
  }
}

button + button,
.button + .button,
button + .button,
.button + button,
.mat-button + .mat-button {
  margin-left: 0.5em;
}
