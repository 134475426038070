@font-face {
  font-family: 'Rocket Sans';
  src:	url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Bold.woff2') format('woff2'),
  url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rocket Sans';
  src:	url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Medium.woff2') format('woff2'),
  url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rocket Sans';
  src:	url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Regular.woff2') format('woff2'),
  url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Regular.woff') format('woff');
  font-weight: $print-body-weight;
  font-style: normal;
}

@font-face {
  font-family: 'Rocket Sans';
  src: 	url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Light.woff2') format('woff2'),
  url('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
