
.mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
}

.mat-dialog-content {
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden !important;
}

.dialog-close {
  position: absolute;
  top: 0.42em;
  right: 0.42em;
  padding: 2px;
  font-size: 30px;
  z-index: 2;
  transition: color $transition-duration, background-color $transition-duration;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  text-align: center;
  color: $color-white;
  cursor: pointer;
  &:hover {
    background-color: $color-black-tint-80;
  }
}
