@import "src/scss/vars";

// -- CUSTOM STYLES SCHEDULE CHANGE REQUESTS ----------------------------------

.centered {
  margin: auto;
  text-align: center;
}

.search-details {
  margin-left: 0.5rem;
  width: 82%;
}

.search-details > * {
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-y: hidden;
  .jobTitle {
    font-size: $font-size-body-md;
  }
}

.search-option {
  font-size: 0.75rem;
  border: 2px solid $color-black-tint-10;
  background-color: $color-white;
  overflow-x: hidden;
  text-align: left;
}

.search-option > div {
  margin: 14px 2.5rem 14px 0.5rem;
  overflow-x: hidden;
}

.plus-icon, .minus-icon, .times-icon {
  color: $color-orbit-purple;
  position: absolute;
  right: 1rem;
  font-size: 16px;
}

.search-field-wrapper {
  position: relative;
  width: 368px;
}

.search-options {
  position: absolute;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1001;
  width: 328px;
  margin-top: 88px;
  width: 100%;
}

.team-member-search-form-field {
  position: relative;
  z-index: 1001;
}

.team-member-search-form-field input {
  background-color: $color-white;
}

.selected-member {
  width: 328px;
  position: relative;
  margin: 20px 0;
}

.selected-members {
  margin-bottom: 18px;
}

.selected-member .search-option,
.selected-members .search-option {
  border-width: 2px;
  border-radius: 5px;
}

.loading-icon {
  color: $color-rocket-red;
}
