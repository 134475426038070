$tooltip-bg: rgba(0,0,0,1);
$tooltip-bg-arrow: $tooltip-bg;
$tooltip-arrow-size: 10px;
$tooltip-font-size: 14px;

.mat-tooltip {

  background-color: $tooltip-bg;
  color: $color-white;
  overflow: visible !important;
  font-size: $tooltip-font-size;
  padding: 6px 16px !important;
  margin: 2px !important;

}
