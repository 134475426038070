html,
body {
  font-size: $font-size-body;
  font-family: $font-body;
  font-weight: normal;
  color: $color-black;
}

strong {
  font-weight: bold;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $font-headline;
  color: $color-black;
  font-weight: bold !important;
  &.no-margin-bottom {
    margin-bottom: 0 !important;
  }
}

h1, .h1 {
  font-size: $font-size-h1;
}

h2, .h2 {
  font-size: $font-size-h2;
}

.mat-typography h1 + p {
  margin-top: 1em;
}

h3, .h3 {
  font-size: $font-size-h3;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  font-size: $font-size-h4;
}

a {
  color: $color-orbit-purple;
  font-weight: bold;
  text-decoration: underline;
  transition: color $transition-duration;

  &:hover {
    color: $color-rocket-red;
  }
}
