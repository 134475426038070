$font-size-field: 14px;
$field-dimensions: 16px;
$radio-circle-size: 0.7em;
$radio-top-offset: 0.2em;
$checkbox-top-offset: $radio-top-offset;
$checkbox-inline-spacing: 1.5em;

label {
  font-size: $font-size-field;
  font-weight: bold;
}

.input-type-checkbox,
.input-type-radio {

  input[type="radio"] {
    margin: 0;
    overflow: visible;
  }

  [type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  [type="radio"]+label {
    position: relative;
    margin-left: 24px !important;
  }

  [type="radio"]+label:before {
    position: absolute;
    left: -1.5em;
    top: $radio-top-offset;
    content: '';
    width: $radio-circle-size;
    height: $radio-circle-size;
    border-radius: 20px;
    border: 2px solid $color-white;
    box-shadow: 0 0 0 2px $color-rkt-gray;
    transition: $transition-duration ease all;
    background-color: $color-white;
  }

  [type="radio"]:hover+label:before {
    background: rgba($color-primary, 0.3);
    box-shadow: 0 0 0 2px rgba($color-primary, 0.3);
  }

  [type="radio"]:checked+label:before {
    background: $color-primary;
    box-shadow: 0 0 0 2px $color-primary;
  }


  [type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  [type="checkbox"]+label {
    position: relative;
    margin-left: 24px;
  }

  [type="checkbox"]+label:before {
    position: absolute;
    left: -1.5em;
    top: $checkbox-top-offset;
    width: $field-dimensions;
    height: $field-dimensions;
    border-radius: 1px;
    box-shadow: 0 0 0 2px $color-rkt-gray;
    font-family: $font-awesome  !important;
    content: '\f00c';
    font-weight: bold;
    color: $color-white;
    font-size: $font-size-field;
    line-height: 100%;
    transition: $transition-duration ease all;
    background-color: $color-white;
  }

  [type="checkbox"]:hover+label:before {
    background: rgba($color-primary, 0.3);
    box-shadow: 0 0 0 2px rgba($color-primary, 0.3);
  }

  [type="checkbox"]:checked+label:before {
    background: $color-primary;
    box-shadow: 0 0 0 2px $color-primary;
  }

}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {

  border: 2px solid $color-black-tint-10 !important;
  border-radius: 5px;
  transition: border-color $transition-duration;
  padding: 10px 40px 10px 12px !important;
  height: 100%;
  font-size: $font-size-body;
  box-sizing: border-box;

  &:focus:not([readonly]) {
    border: 2px solid $color-orbit-purple !important;
    outline: none;
    box-shadow: none;

    + .field-icon {
      color: $color-orbit-purple;
    }
  }

  &:hover {
    border: 2px solid $color-orbit-purple !important;
    box-shadow: none;

    + .field-icon {
      color: $color-orbit-purple;
    }
  }

}

mat-form-field {

  min-width: 248px;
  clear: both;

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-infix {
    border-top-width: 0 !important;
    margin-top: 0 !important;
    width: unset;
  }

  label {
    display: inline-block;
    padding-bottom: 0.5em;
    font-weight: bold;
  }

  .field-icon {
    position: absolute;
    font-size: 1.25em;
    top: 1.95em;
    right: -0.75em;
    color: $color-black-tint-10;
    margin-top: 0.125em;
  }

  .field-hint {
    padding-top: .5em;
    color: $color-gray-dark;
  }

  &:hover {
    border-color: rgba($color-primary, 0.3)
  }

  &.has-icon {

    &.no-label {
      .field-icon {
        top: 0.825em;
      }
    }

    .mat-input-element {
      padding: 10px 40px 10px 12px !important;
      width: 100% !important;
      box-sizing: border-box !important;
      height: 100% !important;
      min-width: 248px !important;
    }

  }

  .mat-form-field-label-wrapper {
    top: 0em;
    left: 1em;

    label {
      opacity: 0.65;
    }
  }

  & .mat-form-field-label {
    margin-top: 1.5em;
  }
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}

mat-radio-button {

  +mat-radio-button {
    margin-left: $checkbox-inline-spacing;
  }

  label {
    font-weight: normal;
  }

  .mat-radio-outer-circle {
    border-color: $color-black-tint-20;
    background-color: $color-white;
  }

  &.mat-radio-checked {

    .mat-radio-outer-circle {
      border-color: rgba($color-orbit-purple, 1.0)  !important;
    }

    .mat-radio-inner-circle {
      background-color: $color-orbit-purple  !important;
    }

  }
}


mat-checkbox {

  +mat-checkbox {
    margin-left: $checkbox-inline-spacing;
  }

  label {
    font-weight: normal;
  }

  .mat-checkbox-frame {
    border-color: $color-black-tint-10;
    background-color: $color-white;
    height: 20px;
    width: 20px;
    left: -2px;
    top: -2px;
  }

  .mat-checkbox-inner-container {
    margin-right: 12px;
  }

  &.mat-checkbox-checked {

    .mat-checkbox-frame {
      border-color: $color-orbit-purple  !important;
      background-color: $color-orbit-purple !important;
    }

    .mat-checkbox-background {
      background-color: $color-orbit-purple  !important;
    }
  }

  &:hover {

    .mat-checkbox-frame {
      border-color: rgba($color-orbit-purple, 0.3) !important;
      background-color: rgba($color-orbit-purple, 0.3) !important;
    }

    .mat-checkbox-background {
      background-color: rgba($color-orbit-purple, 0.3) !important;
    }

    &.mat-checkbox-checked {
      .mat-checkbox-frame {
        border-color: $color-orbit-purple  !important;
        background-color: $color-orbit-purple  !important;
      }

      .mat-checkbox-background {
        border-color: $color-orbit-purple !important;
        background-color: $color-orbit-purple  !important;
      }
    }
  }
}

mat-form-field {

  min-width: 248px;
  clear: both;

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-infix {
    border-top-width: 0 !important;
    margin-top: 0 !important;
  }

  label {
    display: inline-block;
    padding-bottom: 0.5em;
    font-weight: bold;
  }

  &.date-picker {
    .field-icon {
      color: $color-black-tint-10;
      top: 45px;
    }
  }

  &.file-upload {
    .field-icon {
      top: 47px;
    }
  }

  .field-icon {
    position: absolute;
    font-size: 1.25em;
    right: 18px;
    color: $color-black-tint-10;
  }

  &:hover {
    border-color: rgba($color-orbit-purple, 0.3)
  }

  &.has-icon {
    .mat-input-element {
      background-color: $color-white;
      padding: 10px 40px 10px 12px !important;
      min-width: 248px !important;
      width: 100% !important;
    }
  }
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: $color-black  !important;
}

.mat-input-element {
  caret-color: $color-black;
}

@media screen and (max-width: $breakpoint-sm-max) {
  .mat-radio-button+.mat-radio-button {
    margin-left: 0;
  }
}
