// -- FONTS ------------------------------------------------------------------------------------------------------------

$font-awesome: 'Font Awesome 5 Regular';
$font-headline: 'Rocket Sans', RocketSans, "Arial", sans-serif;
$font-body: 'Rocket Sans', RocketSans, "Arial", sans-serif;

$font-size-caption: 11px;

$font-size-tm-badge-sm: 14px;
$font-size-tm-badge-md: 16px;
$font-size-tm-badge-lg: 1em;
$font-size-tm-badge-xl: 20px;
$font-size-tm-badge-2xl: 1.25em;
$font-size-tm-badge-3xl: 1.35em;
$font-size-tm-badge-4xl: 2em;

$font-size-h1: 24px;
$font-size-h2: 22px;
$font-size-h3: 20px;
$font-size-h4: 14px;

$font-size-title-custom-xs: 13px;
$font-size-title-xs: 14px;
$font-size-title: 20px;
$font-size-title-xl: 24px;

$font-size-body-3xs: 8px;
$font-size-body-2xs: 10px;
$font-size-body-xs: 12px;
$font-size-body-sm: 14px;
$font-size-body-md: 15px;
$font-size-body: 16px;
$font-size-body-lg: 18px;
$font-size-body-xl: 20px;
$font-size-body-2xl: 48px;

$font-size-label-lg: 18px;

$font-size-link: 16px;
$font-size-button: 14px;
$font-size-copyright: 14px;

$font-size-xs-icon: 16px;
$font-size-sm-icon: 24px;
$font-size-mobile-menu-icon: 30px;
$font-size-md-icon: 35px;
$font-size-lg-icon: 40px;
$font-size-xl-icon: 60px;

$font-size-sm-span: 14px;

$font-size-callout-title: 20px;
$font-size-schedule-card-mobile: 12px;

$font-size-fa-xl: 28px;
$font-size-fa-2xl: 48px;

$print-body-weight: normal;

// -- SPACERS -----------------------------------------------------------------------------------------------------------
$standard-spacing: 1em;
$full-length: 100%;

// -- COLORS -----------------------------------------------------------------------------------------------------------

$color-primary: #00A69A;
$color-primary-alternative: #00a99d;
$color-primary-light: #4DC0B8;
$color-primary-dark: #008478;

$transparent-background: #f7f7f7b0;

$color-secondary: #B3DBDD;
$color-secondary-light: #C9E5E7;
$color-secondary-dark: #84AAAC;

$color-gray: #EBEBEC;
$color-gray-alternative: #dfdfdf;
$color-rkt-gray: #F7F7F7;
$color-rkt-gray-alternative: #dedee0;
$color-gray-light: #F8F8F9;
$color-gray-dark:  #D3D3D3;

$color-transparent: rgba(0, 0, 0, 0);

$color-warning: orange;
$color-error: #D90000;
$color-black: #000000;
$color-black-alternative: #1C1B1A;
$color-black-tint-80: #494948;
$color-black-tint-20: #D2D1D1;
$color-black-tint-10: #E8E8E8;
$color-black-overlay: #00000080;

$color-black-light: #4D4D4D;
$color-black-medium: #1c1c1a;

$color-white: #FFFFFF;
$color-white-alternative: #e8e8eb;

$color-tm-badge-bg-1: #864e65;
$color-tm-badge-bg-2: #4e8659;
$color-tm-badge-bg-3: #4e8683;
$color-tm-badge-bg-4: #764f86;
$color-tm-badge-bg-5: #867c4e;
$color-tm-badge-bg-6: #4e6886;

$color-classic-red-error: #cc0000;
$color-error-callout-border: #ffcec7;
$color-error-callout-text: #dd1a32;
$color-error-callout-background: rgba(#fce2de, 0.4);

$color-callout-default: $color-black;
$color-callout-primary: $color-primary;
$color-callout-secondary: $color-secondary;
$color-callout-alert: gold;
$color-callout-error: $color-error;
$color-validation-red: #f43d30;

$color-status: $color-black-tint-20;
$color-status-classic: #00a69a;

$color-rocket-red: #c8102e;
$color-classic-red: #f44336;
$color-rocket-red-mid: #9D2235;
$color-rocket-red-deep: #76232F;

$color-orbit-purple: #603AA1;
$color-orbit-purple-light: #9265D3;
$color-orbit-purple-deep: #2E1166;
$color-orbit-purple-lightest: #E7E2F2;
$color-orbit-purple-dark: #3C3174;

$color-success-green: #298540;
$color-info-blue: #1C6EF2;
$color-error-orange: #D47500;

$color-edit-icon: #ccc8c8;

$badge-light-gray:#f8f8f8;
$very-dark-gray: #5a5a5a;
$darker-gray: #686868;
$lighter-gray: rgb(122, 122, 122);
$lighter-black: #222222;

$color-classic-gray-background: #464646;

$app-color-primary: (
  50 : #e0f5f3,
  100 : #b3e5e2,
  200 : #80d4ce,
  300 : #4dc3ba,
  400 : #26b6ac,
  500 : $color-primary-alternative,
  600 : #00a295,
  700 : #00988b,
  800 : #008f81,
  900 : #007e6f,
  A100 : #acfff3,
  A200 : #79ffec,
  A400 : #46ffe5,
  A700 : #2cffe1,
  contrast: (
    50 :  $color-black,
    100 : $color-black,
    200 : $color-black,
    300 : $color-black,
    400 : $color-black,
    500 : $color-white,
    600 : $color-white,
    700 : $color-white,
    800 : $color-white,
    900 : $color-white,
    A100 : $color-black,
    A200 : $color-black,
    A400 : $color-black,
    A700 : $color-black,
  )
);

$app-color-secondary: (
  50 : #f4fafa,
  100 : #e4f2f3,
  200 : #d2eaeb,
  300 : #c0e2e3,
  400 : #b3dbdd,
  500 : #a5d5d7,
  600 : #9dd0d3,
  700 : #93cacd,
  800 : #8ac4c7,
  900 : #79babe,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #dafdff,
  A700 : #c1fcff,
  contrast: (
    50 : $color-black,
    100 : $color-black,
    200 : $color-black,
    300 : $color-black,
    400 : $color-black,
    500 : $color-black,
    600 : $color-black,
    700 : $color-black,
    800 : $color-black,
    900 : $color-black,
    A100 : $color-black,
    A200 : $color-black,
    A400 : $color-black,
    A700 : $color-black,
  )
);

// -- BREAKPOINTS ------------------------------------------------------------------------------------------------------

// Referenced from https://material.angularjs.org/1.1.1/layout/introduction (also in angular-material package)
$breakpoint-xxs-max: 509px;
$breakpoint-xs-min: 510px;
$breakpoint-xs-max: 599px;
$breakpoint-sm-min: 600px;
$breakpoint-sm-max: 959px;
$breakpoint-md-min: 960px;
$breakpoint-md-max: 1279px;
$breakpoint-lg-min: 1280px;
$breakpoint-lg-max: 1919px;
$breakpoint-xl-min: 1920px;

// XS Only
@media screen and (max-width: $breakpoint-xs-max) {}

// GT-XS (greater than XS)
@media screen and (min-width: $breakpoint-sm-min) {}

// SM Only
@media screen and (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-md-max) {}

// GT-SM (greater than SM)
@media screen and (min-width: $breakpoint-sm-min) {}

// MD Only
@media screen and (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {}

// GT-MD (greater than MD)
@media screen and (min-width: $breakpoint-md-min) {}

// LG Only
@media screen and (min-width: $breakpoint-lg-min) and (max-width: $breakpoint-lg-max) {}

// XL and up
@media screen and (min-width: $breakpoint-xl-min) {}

// -- PADDING ----------------------------------------------------------------------------------------------------------

$primary-button-padding: 11px 24px;

// -- BORDERS ----------------------------------------------------------------------------------------------------------

$border-radius-button: 3px;
$border-width-button: 2px;
$border-radius-container: 5px;

$border-radius-icon: 3px;
$border-width-icon: 2px;

$border-radius-radio: 2px;
$border-radius-error-callout: 3px;


// -- TRANSITIONS ------------------------------------------------------------------------------------------------------

$transition-duration: 250ms;



// -- CALENDAR VIEW ----------------------------------------------------------------------------------------------------

$date-circle-size: 32px;
$date-font-size: 14px;
$date-circle-size-mobile: 26px;
