@import "scss/vars";
@import "scss/fonts";




// -- MODIFY ANGULAR MATERIAL BELOW - BE CAREFUL -----------------------------------------------------------------------

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$OnsiteUI-primary: mat-palette($app-color-primary);
$OnsiteUI-accent: mat-palette($app-color-secondary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$OnsiteUI-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$OnsiteUI-theme: mat-light-theme((color: (primary: $OnsiteUI-primary,
        accent: $OnsiteUI-accent,
        warn: $OnsiteUI-warn,
      )));
@include angular-material-theme($OnsiteUI-theme);


$custom-typography: mat-typography-config($font-family: $font-headline,
    $headline: mat-typography-level(24px, 1.25em, 500),
    $title: mat-typography-level(20px, 1.25em, 500),
    $subheading-2: mat-typography-level(14px, 1.25em, 500),
    $subheading-1: mat-typography-level(14px, 1.25em, 500),
    $body-1: mat-typography-level(16px, 1.5em, 400),
    $body-2: mat-typography-level(16px, 1.5em, 500),
    $button: mat-typography-level(14px, 1.5em, 500),
  );
@include angular-material-typography($custom-typography);



// -- CUSTOM STYLES FOR THIS PROJECT -----------------------------------------------------------------------------------
@import "scss/mixins";
@import "scss/typography";
@import "scss/buttons";
@import "scss/icons";
@import "scss/forms";
@import "scss/tm-badge";
@import "scss/dialog";
@import "scss/tooltip";
@import "scss/utility";


html,
body {
  height: 100%;
  background-color: $color-black;
}

body {
  background-color: $color-black;
  margin: 0;
  padding: 0;
}

main {
  background-color: $color-white;
  padding: 1.5em 0;
}

mat-sidenav-content {
  background-color: $color-black;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: $breakpoint-lg-min;
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
}

.footer,
.sidenav {

  .navigation-items {
    list-style-type: none;
    display: inline;
  }

  a:not(.logo-link) {
    padding: 5px 0;
    font-size: $font-size-body;
    font-weight: normal;
    color: $color-white;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    white-space: nowrap;
    transition: border-bottom-color $transition-duration;

    &.active,
    &:hover {
      border-bottom-color: $color-white;
    }
  }

  @media only screen and (max-width: 600px) {
    a:not(.logo-link) {
      white-space: normal;
    }
  }
}

a.logo-link {
  line-height: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none !important;

  &:hover {
    padding: 0;
  }
}

.mat-select-value, .mat-option-text {
  font-weight: 500;
}

.mat-tooltip-trigger {
  > i {
    font-size: 16px;
  }
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.mat-drawer-backdrop.mat-drawer-shown,
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color: rgba($color-black, 0.5) !important;
}


.stats-table {

  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
  font-style: normal;

  td,
  th {
    padding: 0.5em 1em;
    width: 50%;
  }

  tr:nth-child(odd) {
    background-color: $color-rkt-gray;
  }

  td:nth-child(2) {
    text-align: right;
  }

  tr:nth-child(odd),
  tr:nth-child(even) {
    transition: background-color $transition-duration;

    &:hover {
      background-color: $color-secondary-light;
    }
  }

}


.tm-count {

  text-transform: uppercase;
  color: $color-black;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  background-color: $color-white;
  margin: 2px;
  padding: 0;
  font-weight: bold;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include tmBadgeSize(36px, $font-size-tm-badge-sm, 4px);

}

.mat-datepicker-content .mat-calendar {
  height: 375px !important;

  .mat-button-base {
    color: $color-black;
    background-color: $color-transparent;
  }

  .mat-button-disabled {
    background-color: $color-white;
    color: $color-gray-light
  }

  .mat-calendar-previous-button {
    background-color: $color-white
  }

  .mat-calendar-next-button {
    background-color: $color-white
  }

  .mat-calendar-body-cell-content:hover {
    background-color: $color-orbit-purple-lightest  !important;
    color: $color-white;
  }

  .mat-calendar-body-selected {
    background-color: $color-orbit-purple  !important;
    color: $color-white;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: $color-orbit-purple-lightest;
    color: $color-white;
  }

}

.big-tooltip {
  max-width: unset !important;
}

.lksc-component-wrapper {
  .footer-magic-o {
    display: none !important;
  }
}


// -- CUSTOM STYLES SCHEDULE CHANGE REQUESTS ----------------------------------
@import "scss/schedule";
