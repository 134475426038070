
@mixin iconStyling( $bgColor, $bgColorHover ) {

  display: inline-block;
  color: $color-black;
  background-color: $bgColor;
  border-width: $border-width-icon;
  border-color: $bgColor;
  border-radius: $border-radius-icon;
  padding: 0.5em 1.25em;
  transition: background-color $transition-duration, border-color $transition-duration;
  font-size: 14px;
  margin-right: 0.5em;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &.hover {
    color: $color-black;
    background-color: $bgColorHover;
    border-color: $bgColorHover;
    cursor: pointer;
  }

  &[disabled],
  &.disabled {
    opacity: 0.3;
    &:hover {
      background-color: $bgColor;
      border-color:  $bgColor;
    }
  }

}

.icon-button {

  @include iconStyling( $color-rkt-gray, $color-gray-dark );

  &.clear {
    @include iconStyling( transparent, $color-rkt-gray );
  }

}
